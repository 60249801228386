@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TT Alientz';
  src: url('./assets/fonts/TT Alientz.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Cacher la barre de scroll tout en maintenant le défilement */
html,
body {
  overflow: auto; /* Empêche le scroll au niveau global */
}

* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

*::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge, Opera */
}
